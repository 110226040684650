<div class="card" *ngIf="!isNewJourney">
  <div class="card-body">
  </div>
</div>
<ng-container *ngIf="!isBracCyberSource; else bracCyberSource">
  <app-confirm-modal #paymentModalPopup [title]="title" imageUrl="assets/images/material-error.svg"
    [acceptText]="acceptText" titleClass="" [showReject]="false" [showCloseIcon]="false"
    (onAcceptClicked)="onAcceptClicked()" (onClose)="onAcceptClicked()">
  </app-confirm-modal>
</ng-container>
<ng-template #bracCyberSource>
  <div class="form-display">
    <form [formGroup]="formData" action="{{paymentUrl}}" method="post" name="bracForm" id="bracForm" #payDollarForm>
      <input type="text" id="access_key" name="access_key" formControlName="access_key">
      <input type="text" id="profile_id" name="profile_id" formControlName="profile_id">
      <input type="text" id="transaction_uuid" name="transaction_uuid" formControlName="transaction_uuid">
      <input type="text" id="signed_field_names" name="signed_field_names" formControlName="signed_field_names">
      <input type="text" id="unsigned_field_names" name="unsigned_field_names" formControlName="unsigned_field_names">
      <input type="text" id="signature" name="signature" formControlName="signature">
      <input type="text" id="signed_date_time" name="signed_date_time" formControlName="signed_date_time">
      <input type="text" id="locale" name="locale" formControlName="locale">
      <input type="text" id="transaction_type" name="transaction_type" formControlName="transaction_type">
      <input type="text" id="reference_number" name="reference_number" formControlName="reference_number">
      <input type="text" id="auth_trans_ref_no" name="auth_trans_ref_no" formControlName="auth_trans_ref_no">
      <input type="text" id="amount" name="amount" formControlName="amount">
      <input type="text" id="currency" name="currency" formControlName="currency">
      <input id="override_custom_receipt_page" name="override_custom_receipt_page"
        formControlName="override_custom_receipt_page" />
      <input name="override_custom_cancel_page" name="override_custom_cancel_page"
        formControlName="override_custom_cancel_page" />
      <input class="inputbox" type="text" id="bill_to_forename" name="bill_to_forename" value="{{bill_to_forename}}">
      <input class="inputbox" type="text" id="bill_to_surname" name="bill_to_surname" value="{{bill_to_surname}}">
      <input class="inputbox" type="text" id="bill_to_address_line1" name="bill_to_address_line1"
        value="{{bill_to_address_line1}}">
      <input class="inputbox" type="text" id="bill_to_address_state" name="bill_to_address_state"
        value="{{bill_to_address_state}}">
      <input class="inputbox" type="text" id="bill_to_address_city" name="bill_to_address_city"
        value="{{bill_to_address_city}}">
      <input class="inputbox" type="text" id="bill_to_address_country" name="bill_to_address_country"
        value="{{bill_to_address_country}}">
      <input class="inputbox" type="text" id="bill_to_email" name="bill_to_email" value="{{bill_to_email}}">
      <input class="inputbox" type="text" id="bill_to_address_postal_code" name="bill_to_address_postal_code"
        value="{{bill_to_address_postal_code}}">
      <input value="Submit" type="submit">
    </form>
  </div>
</ng-template>