<feature-toggle-provider [features]="featureFlags">
  <div class="girl-shape"></div>
  <div
    class="flexbox flex-column content align-items-center align-items-xxl-start"
  >
    <div class="all-content container-fluid p-0">
      <div class="card-holder">
        <ng-container *ngIf="paymentMethods.length > 0 && !veritransMethodSelected">
          <h2
            *ngIf="!offlineMethodSelected"
            class="content-title d-none d-lg-block"
            i18n
          >
            Please provide your payment details to book your test
          </h2>
          <h2
            *ngIf="offlineMethodSelected"
            class="content-title d-none d-lg-block"
            i18n
          >
            Please follow the instructions to complete payment
          </h2>
        </ng-container>
        <app-circle-sidebar
          i18n-stTitle
          [stTitle]="'Speaking test'"
          [stDate]="state.speakingtesttime"
          i18n-lrwTitle
          [lrwTitle]="'Listening, Reading and Writing test'"
          i18n-lrwTitleMd
          [lrwTitleMd]="'Listening, Reading & Writing test'"
          [lrwDate]="state.lrwtesttime"
          [showPrice]="false"
          i18n-circleClass
          [circleClass]="'red'"
          i18n-textClass
          [textClass]="'gray'"
          class="d-block d-lg-none mb-3"
          [testLocalTimezone]="testLocalTimeZone$ | async"
          [isNotIOLProduct]="isNotIOLProduct"
        >
        </app-circle-sidebar>
        <div *ngIf="paymentMethods.length > 0" class="w-75">
          <app-payment
            [paymentMethods]="paymentMethods"
            [paymentType]="'Normal'"
            (onMethodChange)="onCardTypeChange($event)"
          >
            <ng-template paymentPanel="TIPS">
              <app-tips
                [application]="currentApp"
                [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId"
                [paymentType]="'IOC'"
              >
              </app-tips>
            </ng-template>
            <ng-template paymentPanel="STRIPE">
              <app-stripe
                [application]="currentApp"
                [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId"
                [paymentType]="'IOC'"
              >
              </app-stripe>
            </ng-template>
            <ng-template paymentPanel="PAYSTACK">
              <app-paystack [tcPaymentMethod]="tcPaymentMethod"
              [applicationPaymentId]="applicationPaymentId"
              [applicationId]="applicationId"
              [application]="currentApp"
              [paymentType]="'IOC'" [isNewJourney]="false">
              </app-paystack>
            </ng-template>
            <ng-template paymentPanel="CREDIT_CARD">
              <app-payment-card *featureToggle="'!mockBraintree'"
                isPaymentCard
                #paymentCardElem
                [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId"
                (onCardTypeChange)="onCardTypeChange($event)"
                (onValidityChange)="onValidityChange($event)"
                (onPaymentStatusChange)="onPaymentStatusChange($event)"
                (onCreatedBraintree)="onCreateBraintree()"
                (onCreateError)="onCreateError()"
              >
              </app-payment-card>
              <app-mock-payment-card *featureToggle="'mockBraintree'"
                isPaymentCard
                #paymentCardElem
                [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId"
                (onCardTypeChange)="onCardTypeChange($event)"
                (onValidityChange)="onValidityChange($event)"
                (onPaymentStatusChange)="onPaymentStatusChange($event)"
                (onCreatedBraintree)="onCreateBraintree()"
                (onCreateError)="onCreateError()"
              >
              </app-mock-payment-card>
              <div class="w-100" *ngIf="enable3DSPayment">
                <app-billing-address-form
                  [form]="addressDetailsForm"
                ></app-billing-address-form>
              </div>
              <div #btnSection>
                <button
                  #payBtn
                  [disabled]="
                    !isPayButtonEnable ||
                    (enable3DSPayment && !this.addressDetailsForm?.valid)
                  "
                  class="btn btn-highlight payBtn"
                  [class]="
                    paymentStatus !== 'Error'
                      ? 'btn-payAction'
                      : 'btn-errorAction'
                  "
                  type="submit"
                  (click)="OnPayClick()"
                  i18n
                >
                  <span *ngIf="paymentStatus === ''">
                    Pay
                    {{ this.pay | currency : this.payCode : "symbol-narrow" }}
                    {{ this.payCode }}
                  </span>
                  <span *ngIf="paymentStatus === 'Error'">
                    Payment failed, please retry with a different card
                  </span>
                  <span *ngIf="paymentStatus === 'Process'">
                    Processing payment
                  </span>
                </button>
              </div>
              <div class="bottom-section">
                <div class="wrapper">
                  <span class="sub-copy" i18n
                    >By proceeding, you agree to the Terms and Conditions</span
                  >
                  <div class="braintree">
                    <span class="material-icons icons-security">
                      security
                    </span>
                    <i class="fa fa-lock icons-lock"></i>
                    <img
                      src="./../../../../assets/images/payment/Icon-payment-braintree.svg"
                      class="braintree-img" alt="payment icon"
                    />
                    <span class="braintree-text" i18n
                      >Payment is secured via an SSL 256bit encrypted
                      gateway</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template paymentPanel="PAYPAL" *ngIf="clientId">
              <app-paypal
                isPaymentCard
                [currency]="payCode"
                [clientId]="clientId"
                [purchaseItems]="purchaseItem"
                [purchaseTotal]="pay"
                (onApproval)="onApprovePaypal($event)"
                (onError)="onErrorPaypal($event)"
                [applicationId]="applicationId"
                [profileId]="profileId"
                [applicationPaymentId]="paymentId"
              >
              </app-paypal>
            </ng-template>
            <ng-template paymentPanel="VERITRANS">
              <app-veritrans
              [application]="currentApp"
              [tcPaymentMethod]="tcPaymentMethod"
                [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId"
                [paymentType]="'IOC'" [isNewJourney]="false">
              </app-veritrans>
            </ng-template>
            <ng-template paymentPanel="BANKALFALAH">
              <app-bank-alfalah [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-bank-alfalah>
            </ng-template>
            <ng-template paymentPanel="BRAC">
              <app-brac [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false" [isBracCyberSource]="bracCyberSource">
              </app-brac>
            </ng-template>
            <ng-template paymentPanel="ESEWA">
              <app-e-sewa [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-e-sewa>
            </ng-template>
            <ng-template paymentPanel="PAYDOLLAR">
              <app-paydollar [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-paydollar>
            </ng-template>
            <ng-template paymentPanel="NEWEBPAY">
              <app-newebpay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-newebpay>
            </ng-template>
            <ng-template paymentPanel="INIPAY">
              <app-inipay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-inipay>
            </ng-template>
            <ng-template paymentPanel="HSBCOMNI">
              <app-hsbc-omni [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
                [applicationId]="applicationId" [application]="currentApp" [paymentType]="'IOC'" [isNewJourney]="false">
              </app-hsbc-omni>
            </ng-template>
            <ng-template paymentPanel="OFFLINE">
              <app-offline [testLocationId]="testLocationId" isPaymentCard (onContinue)="onOfflineContinue()">
              </app-offline>
            </ng-template>
          </app-payment>
        </div>
      </div>
    </div>
  </div>
</feature-toggle-provider>
<app-content-modal
  #paymentErrorModal
  title="Payment Error"
  subTitle="Oops, something went wrong, please try again later."
  [showReject]="false"
  [showAccept]="true"
  (onAcceptClicked)="backToMyAccount()"
></app-content-modal>
<app-confirm-modal
  #paymentErrorModalPaypal
  title="Payment Error"
  [acceptText]="'Close'"
  [showReject]="false"
  (onAcceptClicked)="onAcceptClicked()"
  imageClass="fa fa-exclamation-triangle fa-5x text-warning"
></app-confirm-modal>
