import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef, OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Application, DashboardItem } from '@idp-education/ors-test-taker-bff-client-v1';
import { Store } from '@ngrx/store';
import { isNumber, isString } from 'lodash';
import { Router } from '@angular/router';
import {
  ILRWTime,
  ISpeakingTime,
  SetIsRegistered,
  SetLatestPageUrl,
  SetLRWRedirectUrl,
  SetLRWTestTime,
  SetNonIOLLocationId,
  SetNonIOLProductId,
  SetProductType,
  SetSpeakingRedirectUrl,
  setSpeakingTestTime,
  SetTestLocalTimeZone
} from 'src/app/pages/booking/store/booking.actions';
import { setCurrentApplication } from 'src/app/store/applications/application.action';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { setOSRCurrentApplication, setCallToActionState } from 'src/app/store/my-tests/my-tests.actions';
import { CallToActionState, selectCallToActionState } from 'src/app/store/my-tests/my-tests.reducer';
import { setLrwtesttime, setSpeakingtesttime } from 'src/app/pages/payment/store/payment.actions';
import { DateTime, Duration } from 'luxon';
import { CalcRemainingTimeService } from '../../../services/calcRemaining-time.service';
import { first } from 'rxjs/operators';
import { isOSRIOCProduct } from 'shared/utils/is-ioc-product';
import { IBookingStoreState } from 'pages/booking/store/booking.reducer';
import { paymentIState } from 'pages/payment/store/payment.reducer';

@Component({
  selector: 'app-upcoming-test-unpaid',
  templateUrl: './upcoming-test-unpaid.component.html',
  styleUrls: ['./upcoming-test-unpaid.component.scss']
})
export class UpcomingTestUnpaidComponent implements OnInit, OnDestroy {
  hasUnpaidOSRApp: boolean;
  applicationData: Application;
  speakingAndLrw;
  @Input() targetTime: string;
  @Input() targetUnit = 'seconds';
  @Input() application: DashboardItem;
  @Output() onTimerDone: EventEmitter<void> = new EventEmitter();
  @ViewChild('timer') timer: ElementRef;
  worker: Worker;
  state: paymentIState;
  isNewPaymentJourney: string;
  lrwDateTime: { day: string; date: string; month: string; time: string; toTime: string, year: string, monthNumber: number };

  speakingDateTime: { day: string; date: string; month: string; time: string; toTime: string, year, monthNumber: number };
  private _showTime: string | null = null;
  public get showTime(): string | null {
    return this._showTime;
  }

  public set showTime(v: string | null) {
    this._showTime = v;
    if (v === null) {
      this.onTimerDone.emit();
    }
  }

  isButtonDisabled = true;
  intervalTimer;

  constructor(
    private store: Store<{ bookingStore, myTestsStore, paymentStore }>,
    private applicationService: ApplicationsService,
    private loading: LoadingService,
    private router: Router,
    private calcRemainingTime: CalcRemainingTimeService
  ) {
    this.store.select(selectCallToActionState).subscribe(hasUnpaidOSRApp => {
      if (hasUnpaidOSRApp) {
        this.hasUnpaidOSRApp = hasUnpaidOSRApp;
      }
    });
  }

  static getSpeakingAndLRW(app: Application): { speaking: ISpeakingTime, lrw: ILRWTime } | null {
    try {
      const lrw = app.bookings.find(i => (i.bookingLines[0].languageSkill.match(/[LRW]/))?.length > 0);
      const speaking = app.bookings.find(i => i.bookingLines[0].languageSkill === 'S');
      return {
        lrw: {
          from: DateTime.fromISO(lrw?.bookingLines[0]?.startDateTimeUtc, { zone: 'utc' }),
          to: DateTime.fromISO(lrw?.bookingLines[0]?.endDateTimeUtc, { zone: 'utc' }),
          lrw: { ...lrw }
        },
        speaking: {
          from: DateTime.fromISO(speaking?.bookingLines[0]?.startDateTimeUtc, { zone: 'utc' }),
          to: DateTime.fromISO(speaking?.bookingLines[0]?.endDateTimeUtc, { zone: 'utc' }),
          speaking: { ...speaking },
        }
      };
    } catch (error) {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.worker?.postMessage({ key: 'stop' });
  }

  ngOnInit(): void {
    this.isNewPaymentJourney = localStorage.getItem('isNewPaymentJourney');
    localStorage.setItem('isCompleteBooking', JSON.stringify(true));
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('./../../../../../assets/worker-js/timer.worker.js', { type: 'module' });
      this.startTimer();
    }
    if (this.application && this.application.applicationId) {
      this.loading.increaseLoadingCounter();
      this.applicationService.GetApplication(this.application.applicationId).pipe(first()).subscribe(data => {
        this.applicationData = data;
        if (isOSRIOCProduct(data?.bookings[0]?.bookableProductName)) {
          this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: this.applicationData }));
          this.loading.decreaseLoadingCounter();
        } else {
          this.store.dispatch(setCurrentApplication({ application: data }));
          this.speakingAndLrw = UpcomingTestUnpaidComponent.getSpeakingAndLRW(data);
          if (!this.speakingAndLrw) {
            alert('something failed');
            return;
          }
          this.store.dispatch(setSpeakingTestTime(this.speakingAndLrw?.speaking));
          this.store.dispatch(SetLRWTestTime(this.speakingAndLrw?.lrw));
          this.store.dispatch(SetIsRegistered());
          this.loading.decreaseLoadingCounter();
        }
      });
    }
  }

  startTimer() {
    const toDuration = (date: number | string) => {
      if (isNumber(date) || (isString(date) && isNumber(parseInt(date.toString())))) {
        return Duration.fromObject({
          seconds: parseInt(date.toString())
        });
      } else {
        console.error('target time is not valid');
      }
    };
    const totalDuration = toDuration(this.targetTime);
    this.worker.postMessage({ key: 'duration', value: totalDuration.as('seconds') });
    this.worker.postMessage({ key: 'start' });
    this.worker.onmessage = ({ data }) => {
      const duration = toDuration(data);
      this.fillShowTime(duration);
      this.manipulateButton(duration);
      if (data < 0) {
        this.worker.postMessage({ key: 'stop' });
      }
    };
  }

  setSpeakingTestTimeAction(data) {
    return setSpeakingtesttime(data);
  }

  setLrwTestTimeAction(data) {
    return setLrwtesttime(data);
  }

 

  private manipulateButton(distance: Duration) {
    if (distance.as('seconds') >= 1) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  private fillShowTime(distance: Duration) {
    this.showTime = this.calcRemainingTime.getTime(distance.as('seconds'));
  }
  restoreBookingInfo() {
    const bookingStoreStates = localStorage.getItem('bookingstore');
    if (bookingStoreStates) {
      const bookingStoreState: IBookingStoreState = JSON.parse(bookingStoreStates);
      this.store.dispatch(SetSpeakingRedirectUrl({
        speakingUrl: bookingStoreState.speakingUrl
      }));
      this.store.dispatch(SetProductType({
        isNotIOLProduct: true
      }));
      this.store.dispatch(SetTestLocalTimeZone({
        testLocalTimezone: bookingStoreState.testLocalTimezone
      }));
      this.store.dispatch(SetLRWRedirectUrl({
        lrwUrl: bookingStoreState.lrwUrl
      }));
      this.store.dispatch(SetNonIOLProductId({
        nonIOLProductId: bookingStoreState.nonIOLProductId
      }));
      this.store.dispatch(SetNonIOLLocationId({
        nonIOLLocationId: bookingStoreState.nonIOLLocationId
      }));
      this.store.dispatch(SetLatestPageUrl({
        latestPage: bookingStoreState.latestPageUrl
      }));
    }
  }


  setProccessPayment() {
    if (isOSRIOCProduct(this.application?.productName)) {
      this.store.dispatch(setCallToActionState({ callToActionState: CallToActionState.OSR_PRE_PAYMENT }));
      this.router.navigate(['/my-tests']);
    } else {
      // To handle overlapping IOL and IOC -20869
      if (!this.application?.productName.toLocaleLowerCase().includes('on computer')) {
        this.store.dispatch(SetProductType({
          isNotIOLProduct: false
        }));
      }
      this.store.dispatch(this.setSpeakingTestTimeAction({ speakingtesttime: this.speakingAndLrw?.speaking }));
      this.store.dispatch(this.setLrwTestTimeAction({ lrwtesttime: this.speakingAndLrw?.lrw }));

      if (this.isNewPaymentJourney === 'enabled') {
        this.restoreBookingInfo();
        localStorage.setItem('isCompleteBooking',JSON.stringify(true));
        this.router.navigate(['/account-v2/booking-summary/payment-options']);
      }
      else {
        this.router.navigate(['/payment/card-detail']);
      }

    }
  }
  formDateObj (dateTimeObj) {
    return {
      day: this.getDayName(dateTimeObj),
      date: this.getDay(dateTimeObj),
      month: this.getMonthName(dateTimeObj),
      time: this.getTime(dateTimeObj),
      toTime: this.getTime(dateTimeObj),
      year: this.getYearNumber(dateTimeObj),
      monthNumber: this.getMonthNo(dateTimeObj)
    };
  }

  getTime(time: DateTime | null | undefined): string {
    if (!time) {
      return '';
    }
    return time.toFormat('h:mm a').toLowerCase();
  }
  isDateValid(d: DateTime | null) {
    return DateTime.isDateTime(d);
  }
  getDay(date: DateTime): string {
    return this.isDateValid(date) ? date.toFormat('d') : '';
  }

  getMonthNo(date: DateTime): number {
    return this.isDateValid(date) ? date.month : 0;
  }
  getDayName(date: DateTime): string {
    if (this.isDateValid(date)) {
      return date.toFormat('ccc');
    }
    return '';
  }

 
 
  getYearNumber(date: DateTime): string {
    return this.isDateValid(date) ? date.toFormat('yyyy') : '';
  }
  getMonthName(date: DateTime): string {
    return this.isDateValid(date) ? date.toFormat('MMM') : '';
  }
}
