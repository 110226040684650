import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';
export type IState = {
  password?: string,
  email?: string,
  code?: string,
  completeForgetPassword?: boolean
  validationMessage?: ''
};

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {
  paymentNameChanged: EventEmitter<string> = new EventEmitter<string>();
  private paymentReceiptNumber = new BehaviorSubject<any>(null);
  
  // Observable stream to be subscribed to
  paymentReceiptNo$: Observable<any> = this.paymentReceiptNumber.asObservable();

  testLocationId: EventEmitter<string> = new EventEmitter<string>();
  triggerSubmit: EventEmitter<any> = new EventEmitter();
  paymentOptionsPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  offlinePaymentPage: EventEmitter<boolean> = new EventEmitter<boolean>();
  backBtnStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  offlineDataTransfer: EventEmitter<any> = new EventEmitter();
  offlineDataSubject = new BehaviorSubject<any>(null);
  fromBookingSummaryPageSubject = new BehaviorSubject<boolean>(false);
  fromBookingSummaryPage$ = this.fromBookingSummaryPageSubject.asObservable();
  // Observable stream to be subscribed to
  offlineDataFromBooking$: Observable<any> = this.offlineDataSubject.asObservable();
  closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  public paymentMethodsSubject = new BehaviorSubject<any>({ tcPaymentMethod: [], paymentMethods: [], currentApp: [] });
  public paymentMethods = this.paymentMethodsSubject.asObservable();
  private state$ = new BehaviorSubject<IState>({
  });
 
  changeState(myChange) {
    this.state$.next(myChange);
  }

  getState() {
    return this.state$.asObservable();
  }

  updatePaymentName(newName: string) {
    this.paymentNameChanged.emit(newName);
  }

  sendTestLocationId(val:any){
    this.testLocationId.emit(val);
  }

  triggerOnSubmit(componentName: string, typeVal?:any): void {
    const paymentDetails ={
      componentName: componentName,
      typeVal: typeVal
    }
    this.triggerSubmit.emit(paymentDetails);
  }

  triggerPaymentOptionsPage(showPaymentOptions: boolean) {
    this.paymentOptionsPage.emit(showPaymentOptions);
  }

  triggerOfflinePaymentPage( offlinePage : boolean) {
    this.offlinePaymentPage.emit(offlinePage)
  }
  
  triggerBackBtnStatus(backBtnValue: boolean) {
    this.backBtnStatus.emit(backBtnValue);
  }

  isBookingSummary(val: boolean): void {
    this.fromBookingSummaryPageSubject.next(val);
  }

  triggerReceiptNumber(receiptNumber: string) {
    this.paymentReceiptNumber.next(receiptNumber);
  }

  sendOfflineData(val: any) {
    this.offlineDataTransfer.emit(val);
  }

  sendFromBookingSummary(data: any): void {
    this.offlineDataSubject.next(data);
  }

  closeOverlay(){
    this.closePopup.emit(false);
  }

  getLocalStorageValue(key: any, parseData?: boolean) {
    const details = localStorage.getItem(key);
    return (parseData && details) ? JSON.parse(details) : details || '';
  }

  private isDate(d: DateTime | null): boolean {
    return DateTime.isDateTime(d);
  }

   getTimes(time: DateTime | null | undefined, testLocalTimezone): string {
      if (!time || !(time instanceof DateTime)) {
        return '';
      }
      const localTime = time.setZone(testLocalTimezone);
      return localTime.toFormat('h:mm a').toLowerCase();
    }
  
    getDay(date: DateTime, testLocalTimezone): string {
      return this.isDate(date) ? date.setZone(testLocalTimezone).toFormat('d') : '';
    }
  
    getDayName(date: DateTime, testLocalTimezone): string {
      return this.isDate(date) ? date.setZone(testLocalTimezone).toFormat('ccc') : '';
    }
  
    getMonth(date: DateTime, testLocalTimezone): string {
      return this.isDate(date) ? date.setZone(testLocalTimezone).toFormat('MMM') : '';
    }
  
    getMonthNumber(date: DateTime, testLocalTimezone): number {
      return this.isDate(date) ? date.setZone(testLocalTimezone).month : 0;
    }
  
    getYear(date: DateTime, testLocalTimezone): string {
      return this.isDate(date) ? date.setZone(testLocalTimezone).toFormat('yyyy') : '';
    }

    formDateObj (dateTimeObj, testLocalTimezone) {
      return {
        day: this.getDayName(dateTimeObj, testLocalTimezone),
        date: this.getDay(dateTimeObj, testLocalTimezone),
        month: this.getMonth(dateTimeObj, testLocalTimezone),
        time: this.getTimes(dateTimeObj, testLocalTimezone),
        toTime: this.getTimes(dateTimeObj, testLocalTimezone),
        year: this.getYear(dateTimeObj, testLocalTimezone),
        monthNumber: this.getMonthNumber(dateTimeObj, testLocalTimezone)
      };
    }

}
