import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener,
  OnDestroy, OnInit, Output, Renderer2, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from '../../../../shared/services/user-profile.service';
import { concatMap, delay, first, map, take } from 'rxjs/operators';
import { TestCentreService } from '@idp-education/ors-test-taker-bff-client-v1/api/testCentre.service';
import { Store } from '@ngrx/store';
import {
  selectOSRApplication,
  selectSelectedTest,
  selectSsrComponent,
  selectSSRFee,
  selectTestCentreAddress,
  selectTestCentreName
} from '../../../../store/my-tests/my-tests.reducer';
import { customDateFormat } from '../../../../shared/utils/custom-date-format';
import { CreditCardComponent } from 'src/app/shared/components/payment/credit-card/credit-card.component';
import {
  Application,
  ApplicationResponse,
  CreateApplicationResponse,
  GetTestLocationProductFeeResponse,
  LanguageSkill,
  PaymentMethod,
  ProductFee,
  ProductFeeService,
  ReceiptService,
  UserProfile,
  UserProfileAddressDetails
} from '@idp-education/ors-test-taker-bff-client-v1';
import { Observable, Subscription } from 'rxjs';
import { ApplicationsService } from 'src/app/shared/services/applications.service';
import {
  IPaymentStatus, setOfflinePayment,
  setPayingStatus
} from 'src/app/pages/payment/store/payment.actions';
import * as braintree from 'braintree-web';
import { LoadingService } from 'src/app/shared/services/loading-service.service';
import { PaymentsService } from 'src/app/shared/services/payment.services';
import * as uuid from 'uuid';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/pages/account/signup/userProfile.model';
import { ToastrService } from 'ngx-toastr';
import { getDuration, getUserName } from '../util';
import {
  ResetState,
  setOSRCurrentApplication, setSelectedTest, setSsrComponent,
  setSSRFee,
  setCallToActionState
} from 'src/app/store/my-tests/my-tests.actions';
import { EverFlowHelper } from 'src/app/shared/helper/everflow.helper';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { getTestCentreCode, initializePaymentMethodV2 } from '../../../../shared/utils/initialize-payment-method';
import { skillsLabel } from '../../EOR/eor-request-form/eor-request-form.component';
import { PurchaseItem } from '@paypal/paypal-js';
import { DateTime } from 'luxon';
import { EMAIL_REGEXP } from 'src/app/shared/sharedRegex';
import { CallToActionService } from '../../../../shared/services/call-to-action.service';
import { getApplicationUserName } from 'store/applications/application.selectors';
import { setCurrentApplication } from 'store/applications/application.action';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  UnderageConsentFormModalComponent
} from 'shared/components/underage-consent-form-modal/underage-consent-form-modal.component';
import { selectConsentAccepted } from 'pages/booking/store/booking.selectors';
import { IPaymentMethods } from 'shared/interfaces/payment.interface';
import { selectFromTips } from 'pages/payment/store/payment.reducer';

declare let dataLayer;

@Component({
  selector: 'app-ssr-payment',
  templateUrl: './ssr-payment.component.html',
  styleUrls: ['./ssr-payment.component.scss']
})
export class SsrPaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  state: any;
  selectedType = 'credit';
  threeDSecure: any;
  userProfile = '';
  application: CreateApplicationResponse;
  currentApp: Application;
  sub: Subscription;
  token: string;
  paymentStatus: '' | 'Error' | 'Process' = '';
  cardErrors = [];
  showCvvModal = false;
  cardValidate = false;
  amount: number;
  taxAmount: number;
  productName: string;
  productId: string;
  testLocationId: string;
  cardNumberValid = false;
  pay: number;
  payCode: string;
  enable3DSPayment: boolean;
  timerTargetTime = null;
  timerCurrentTime = null;
  isPayButtonEnable = false;
  testStartUtcDatetime;
  getTimeString;
  address;
  testCentreName;
  testFormat;
  testType;
  ssrComponent;
  addressDetailsForm: UntypedFormGroup;
  consentFormModalRef?: NgbModalRef;
  selectedTest$ = this.store.select(selectSelectedTest);
  ssrComponent$ = this.store.select(selectSsrComponent);
  selectedOSRApp$ = this.store.select(selectOSRApplication);
  address$ = this.store.select(selectTestCentreAddress);
  testCentreName$ = this.store.select(selectTestCentreName);
  fromTips$ = this.store.select(selectFromTips);
  _fromTips = false;
  hostedFieldsInstance: braintree.HostedFields;
  paymentMethods: IPaymentMethods[] = [];
  getApplicationUserName$ = this.store.select(getApplicationUserName);
  tcPaymentMethod = [];
  testStartUtcDatetime$ = this.selectedTest$.pipe(map(test => {
    if (test) {
      const date = test.testStartLocalDatetime;
      const formattedDate = (date && date.length > 6) ? date.substring(0, date.length - 6) : '';
      return new Date(formattedDate);
    }
    return;
  }));
  getTimeString$ = this.selectedTest$.pipe(map((test) => {
    const fromTime = new Date(test.testStartLocalDatetime);
    return customDateFormat(test.testLocalTimeZone, 'time', fromTime);
  }));
  testFormat$ = this.selectedTest$.pipe(map(test => test ? test.testFormat : ''));
  testType$ = this.selectedTest$.pipe(map(test => test ? ([test.testCategory, test.testModule].join(',')) : ''));
  receiptNumber$ = this.selectedOSRApp$.pipe(map(app => {
    if (app && app.applicationPayments && app.applicationPayments.length > 0) {
      return app.applicationPayments[0].receiptNumber;
    } else {
      return '';
    }
  }));
  applicationPaymentId$ = this.selectedOSRApp$.pipe(map(app => {
    if (app && app.applicationPayments && app.applicationPayments.length > 0) {
      return app.applicationPayments[0].id;
    } else {
      return '';
    }
  }));
  @ViewChild('paymentCardElem') paymentCardElem: CreditCardComponent;
  @ViewChild('btnSection') btnSection: ElementRef;
  @ViewChild('restartBookingModal') restartBookingModal: ConfirmModalComponent;
  @ViewChild('paymentErrorModalPaypal') paymentErrorModalPaypal: ConfirmModalComponent;
  @Output() onBackButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() OnPayClicked: EventEmitter<any> = new EventEmitter();
  @Output() restartOSRBooking: EventEmitter<any> = new EventEmitter();

  private _clientId: string;
  bracCyberSource: boolean;
  public get clientId(): string {
    return this._clientId;
  }

  private _profileId: string;
  public get profileId(): string {
    return this._profileId;
  }

  purchaseItem: PurchaseItem[];

  getTestDate$() {
    return this.selectedTest$.pipe(map(test => {
      return DateTime.fromISO(test.testStartLocalDatetime, { setZone: true });
    }));
  }

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private testCentreService: TestCentreService,
    private applicationService: ApplicationsService,
    private paymentservice: PaymentsService,
    private loadservice: LoadingService,
    private feeService: ProductFeeService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private everFlowHelper: EverFlowHelper,
    private toast: ToastrService,
    private callToActionService: CallToActionService,
    private store: Store<{ myTestsStore, paymentStore, globalStore, bookingStore, applicationsStore }>,
    private modalService: NgbModal,
    private receiptService: ReceiptService
  ) {
    this.loadservice.increaseLoadingCounter();
    this.userProfileService.getUserProfile().pipe(first()).subscribe((uProfile) => {
      if (uProfile) {
        this.userProfile = uProfile.firstName;
        this._profileId = uProfile.userProfileId;
        this.getStore();
      } else {
        this.userProfile = null;
      }
    });
    this.enable3DSPayment = this.paymentservice.check3DSstatus();
    this.store.select(appState => appState.paymentStore).pipe(first()).subscribe(x => {
      this.state = x;
    });
    this.testStartUtcDatetime$.pipe(first()).subscribe(data => {
      this.testStartUtcDatetime = data;
    });
    this.getTimeString$.pipe(first()).subscribe(test => {
      this.getTimeString = test;
    });
    this.address$.pipe(first()).subscribe(address => {
      this.address = address;
    });
    this.testCentreName$.pipe(first()).subscribe(testCentre => {
      this.testCentreName = testCentre;
    });
    this.testFormat$.pipe(first()).subscribe(format => {
      this.testFormat = format;
    });
    this.testType$.pipe(first()).subscribe(type => {
      this.testType = type;
    });
    this.ssrComponent$.pipe(first()).subscribe(ssrComponent => {
      this.ssrComponent = ssrComponent;
    });
    this.selectedOSRApp$.pipe(first(), concatMap(osrApp => this.fromTips$.pipe(first(), map(f => {
      this._fromTips = f;
      return osrApp;
    })))).subscribe((application: Application) => {
      this.currentApp = application;
      this.store.dispatch(setCurrentApplication({ application }));
      if (application && application.applicationPayments?.length > 0 && application.bookings[0]) {
        this.callToActionService.setHidden(true);
        const applicationPayment = this.applicationService.getLatestApplicationPayment(application.applicationPayments);
        this.setPaymentDetails(applicationPayment, application);
        const date = application.bookings[0]?.bookingLines[0]?.startDateTimeLocal;
        this.setSSRComponent(application);
        this.setSelectedTestDateTime(date, application);
        this.setTestStartUtcDatetime(date);
        const fromTime = new Date(application.bookings[0]?.bookingLines[0]?.startDateTimeLocal);
        this.getTimeString = customDateFormat(application.bookings[0]?.testLocationTimeZone, 'time', fromTime);
        // TODO : Hardcoded for Interim solution until get api fetches the below info
        this.testType = this.fillTestType();
        this.testFormat = this.productName.includes('on Computer') ? 'CD' : 'IOL';
        this.ssrComponent = !this.ssrComponent ? this.getLanguageSkill(application.bookings[0]?.bookingLines[0]?.languageSkill)
          : this.ssrComponent;

        this.initPaymentMethod(application);

        this.store.select(selectConsentAccepted)
          .pipe(take(1))
          .subscribe((consentAccepted) => {
            if ((application as ApplicationResponse)?.underAgeConsentDetails?.isUnderAgeOnTestDate) {
              if (!consentAccepted) {
                this.openConsentFormPopup();
              }
            }
          });
      }
    });
    this.loadservice.decreaseLoadingCounter();
    this.store.dispatch(setCallToActionState({ callToActionState: null }));
  }

  private fillTestType() {
    const isIncludeGeneralTraining = () => this.productName.includes('General Training');
    if (this.productName?.toLowerCase().includes('ukvi')) {
      return isIncludeGeneralTraining() ? 'IELTS_UKVI,GT' : 'IELTS_UKVI,AC';
    } else {
      return isIncludeGeneralTraining() ? 'IELTS,GT' : 'IELTS,AC';
    }
  }

  private initPaymentMethod(application: Application) {
    initializePaymentMethodV2({
      store: this.store,
      isNotIOLProduct: true,
      locationId: this.currentApp.bookings[0]?.testLocationId,
      testCentreService: this.testCentreService,
      receiptService: this.receiptService,
      testCentreCode: getTestCentreCode(application),
      isOSRorEORProduct: true
    }).subscribe(({ paymentMethod, location, testcentrePaymentMethods }) => {
      this.tcPaymentMethod = testcentrePaymentMethods;
      const isCyberSource = this.tcPaymentMethod?.find(x => x.paymentMethod.description.toLowerCase().includes('cyber'));
      this.bracCyberSource = isCyberSource !== undefined && isCyberSource;
      localStorage.setItem('isBracCyberSource', JSON.stringify(this.bracCyberSource));
      this.paymentMethods = paymentMethod;
      this._clientId = location?.paypalConfiguration?.clientId;
    });
  }

  private setTestStartUtcDatetime(date: string) {
    const formattedDate = (date && date.length > 6) ? date.substring(0, date.length - 6) : '';
    this.testStartUtcDatetime = new Date(formattedDate);
  }

  private setSelectedTestDateTime(date: string, application: Application) {
    this.store.dispatch(setSelectedTest({
      selectedTest: {
        testStartLocalDatetime: date,
        testLocalTimeZone: application.bookings[0]?.testLocationTimeZone
      } as any
    }));
  }

  private setSSRComponent(application: Application) {
    const selectedSkill = application.bookings[0]?.bookingLines
      .find(b => b.status?.toUpperCase() === 'ACTIVE')
      .languageSkill;
    this.store.dispatch(setSsrComponent({
      ssrComponent: skillsLabel[selectedSkill]
    }));
  }

  private setPaymentDetails(applicationPayment, app: Application) {
    this.amount = applicationPayment?.applicationFee?.totalAmount;
    this.pay = this.amount;
    this.payCode = applicationPayment?.applicationFee?.currencyIsoCode;
    const baseAmount = applicationPayment?.applicationFee?.baseAmount;
    this.taxAmount = (applicationPayment?.applicationFee?.totalAmount) - (baseAmount);
    const fixedAmount = (this.taxAmount).toFixed(2);
    this.taxAmount = Number(fixedAmount);
    this.productName = app?.bookings[0].bookableProductName;
    this.productId = app?.bookings[0].bookableProductId;
    this.testLocationId = app?.bookings[0].testLocationId;
    if (!this._fromTips) {
      this.timerTargetTime = DateTime.fromISO(app?.expiryDateTimeUtc, { zone: 'utc' });
    }
    this.timerCurrentTime = DateTime.now();
    this.purchaseItem = [{
      name: this.productName || 'Online Test',
      sku: this.productId || '',
      quantity: `1`,
      category: 'DIGITAL_GOODS',
      unit_amount: {
        value: `${baseAmount}`,
        currency_code: `${this.payCode}`
      },
      tax: {
        value: `${this.taxAmount}`,
        currency_code: `${this.payCode}`
      }
    }];
  }

  ngOnInit(): void {
    this.addressDetailsForm = this.prepareBillingAddressForm();
  }

  get applicationId() {
    return this.currentApp?.id;
  }

  get applicationPaymentId() {
    return Array.isArray(this.currentApp?.applicationPayments) && this.currentApp?.applicationPayments.length
      && this.currentApp?.applicationPayments.reduce((a, b) => (new Date(a.createdOn) > new Date(b.createdOn) ? a : b)).id;
  }

  getLanguageSkill(e: LanguageSkill) {
    return skillsLabel[e];
  }

  onCardTypeChange(event) { /* empty */ }

  onPaymentStatusChange(status: IPaymentStatus) {
    this.store.dispatch(setPayingStatus({ status }));
  }

  private prepareBillingAddressForm() {
    const user = new User();
    const addressDetail: UserProfileAddressDetails = this.currentApp?.userProfile?.addressDetails;
    const userProfile: UserProfile = this.currentApp?.userProfile;
    return this.fb.group({
      email: ['',
        { validators: [Validators.pattern(EMAIL_REGEXP)] }
      ],
      mobileNumber: [''],
    });
  }

  getStore() {
    this.applicationService.GetCurrentOSRApplication({ force: true }).pipe(first()).subscribe((app: Application) => {
      try {
        if (this.paidApplication(app) && !this._fromTips) {
          this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: null }));
        }
        if (this.address === '' || this.testCentreName === '') {
          this.testCentreService.getTestLocation(app?.bookings[0]?.testLocationId).pipe(first()).subscribe(data => {
            if (data) {
              this.address = this.frameAddress(data.addresses[0]);
              this.testCentreName = data.name;
            }
          });
        }
      } catch (error) {
        return null;
      }
    });
  }

  frameAddress(address): string {
    const append = (info: string | undefined, prefix = '') => {
      if (info === undefined || info === '' || info === null) {
        return '';
      } else {
        return prefix + ' ' + info;
      }
    };
    return address.addressLine1 +
      append(address.city) +
      append(address.stateProvince) +
      append(address.zipPostCode);
  }

  paidApplication(app: Application): boolean {
    return !!app?.applicationPayments?.find(payment => payment.status === 'PAID' || payment.status === 'IN_PROGRESS');
  }

  ngAfterViewInit(): void {
    this.CheckButtonSticky();
  }

  OnPayClick() {
    this.cardErrors = [];
    if (!this.hostedFieldsInstance) {
      throw new Error('braintree not created');
    }
    this.paymentCardElem.blurBackground = true;
    this.paymentStatus = 'Process';
    this.isPayButtonEnable = false;
    this.cdr.detectChanges();
    this.loadservice.increaseLoadingCounter();
    if (this.enable3DSPayment) {
      if (this.addressDetailsForm?.valid) {
        this.payWith3DSAuthentication();
      } else {
        return;
      }
    } else {
      this.payWithout3DSAuthentication();
    }

  }

  payWith3DSAuthentication() {
    const findNestedError = (e) => {
      if (e?.details && e.details?.originalError) {
        return findNestedError(e.details?.originalError);
      } else {
        // TODO Need to be discussed with backend
        return `Message: Payment failed. Reason: ${e?.error?.message || ''}`;
      }
    };
    const billingAddressValue = this.addressDetailsForm?.value;
    this.hostedFieldsInstance.tokenize()
      .then((payload) => {
        return this.threeDSecure.verifyCard({
          amount: this.currentApp.applicationPayments[0].amount,
          nonce: payload.nonce,
          bin: payload.details.bin,
          email: billingAddressValue.email,
          billingAddress: {

            phoneNumber: billingAddressValue?.mobileNumber?.e164Number,

          },
          onLookupComplete: (data, next) => {
            next();
          },
        });
      }).then((payload) => {
        if (payload.liabilityShifted) {
          this.doPay('', payload.nonce);
        } else {
          this.loadservice.decreaseLoadingCounter();
          this.setErrorClass();
          console.error('No Liability Shifted');
          // this.createthreeDInstance();
          this.clearHostedFields();
          this.hostedFieldsInstance.teardown();
          this.paymentCardElem.cardNumberValid = false;
          this.paymentCardElem.initPayment();
        }
      }).catch((error) => {
        this.paymentStatus = '';
        this.loadservice.decreaseLoadingCounter();
        this.store.dispatch(setPayingStatus({ status: 'failed' }));
        console.error('error : ', error);
        this.setErrorClass();
        this.clearHostedFields();
        this.hostedFieldsInstance.teardown();
        this.paymentCardElem.cardNumberValid = false;
        this.paymentCardElem.initPayment();
        this.toast.error(findNestedError(error), 'Payment failed');
        throw error;
      });
  }

  payWithout3DSAuthentication() {
    this.hostedFieldsInstance.tokenize()
      .then((payload) => {
        this.doPay('', payload.nonce);
        this.loadservice.decreaseLoadingCounter();
      }).catch((error) => {
        this.paymentStatus = '';
        this.store.dispatch(setPayingStatus({ status: 'failed' }));
        console.log(error);
        this.setErrorClass();
        this.clearHostedFields();
      });
  }

  clearHostedFields() {
    this.hostedFieldsInstance.clear('number');
    this.hostedFieldsInstance.clear('cvv');
    this.hostedFieldsInstance.clear('expirationMonth');
    this.hostedFieldsInstance.clear('expirationYear');
    this.hostedFieldsInstance.clear('cardholderName');
  }

  setErrorClass() {
    this.paymentStatus = 'Error';
    this.renderer.addClass(this.paymentCardElem?.cardSection?.nativeElement, 'shake');

    this.cdr.detectChanges();
    setTimeout(() => {
      this.paymentStatus = '';
      this.renderer.removeClass(this.paymentCardElem?.cardSection?.nativeElement, 'shake');
      this.isPayButtonEnable = false;
      this.paymentCardElem.blurBackground = false;
    }, 2000);
  }

  doPay(mode, nonce) {
    this.store.dispatch(setOfflinePayment({
      isOffline: false
    }));
    this.loadservice.increaseLoadingCounter();
    this.paymentservice.DoPayment(mode ?? '', nonce, this.currentApp, this.paymentCardElem.deviceData, this.paymentCardElem.token)
      .pipe(first(), delay(3000)).subscribe(x => {
        this.onPaymentSuccess(x);
        this.loadservice.decreaseLoadingCounter();
      },
        e => {
          this.loadservice.decreaseLoadingCounter();
          this.store.dispatch(setPayingStatus({ status: 'failed' }));
          this.setErrorClass();
          this.clearHostedFields();
          this.hostedFieldsInstance.teardown();
          this.paymentCardElem.cardNumberValid = false;
          this.paymentCardElem.initPayment();
        },
        () => { /* empty */ });
  }

  onPaymentSuccess(x?: any) {
    this.everFlowHelper.processEverflowConversion(this.currentApp);
    this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: this.currentApp }));
    this.store.dispatch(setCallToActionState({ callToActionState: null }));
    this.OnPayClicked.emit();
  }

  CheckButtonSticky() {
    if (!this.btnSection) {
      return;
    }
    const elem = this.paymentCardElem?.cardBackground?.nativeElement;
    if (document.documentElement.clientHeight + document.documentElement.scrollTop >=
      (elem?.offsetHeight + this.paymentCardElem?.cardContent?.nativeElement?.offsetTop + 230 + 30)) {
      this.renderer.removeClass(this.btnSection.nativeElement, 'btn-sticky');
    } else {
      this.renderer.addClass(this.btnSection.nativeElement, 'btn-sticky');
    }
  }

  onValidityChange({ isValid, errors }) {
    this.cardErrors = errors;
    this.cardNumberValid = isValid;
    this.isPayButtonEnable = isValid;
    this.cdr.detectChanges();
  }

  onCreateBraintree() {
    this.hostedFieldsInstance = this.paymentCardElem.hostedFieldsInstance;
    this.threeDSecure = this.paymentCardElem.threeDSecureInstance;
  }

  onCreateError() {
    this.loadservice.resetLoadingCounter();
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    if (this.paymentStatus === 'Process') {
      $event.returnValue = true;
    }
  }

  @HostListener('document:scroll', ['$event'])
  @HostListener('window:resize', ['$event'])
  public onScroll(target) {
    this.CheckButtonSticky();
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.showCvvModal = false;
  }

  getDuration$() {
    return this.ssrComponent$.pipe(map(ssrComponent => getDuration(ssrComponent).toString()));
  }

  whenTimerDone() {
    this.sub = this.store.select(appState => appState.paymentStore.paymentStatus).subscribe((ps: IPaymentStatus) => {
      if (ps === 'failed' || ps === 'not started') {
        this.store.dispatch(setOSRCurrentApplication({ currentOSRApplication: null }));
        this.store.dispatch(setCallToActionState({ callToActionState: null }));
        if (this.consentFormModalRef) {
          this.consentFormModalRef.dismiss();
        }
        if (this.restartBookingModal) {
          this.restartBookingModal.open();
        }
      }
    });
  }

  timeOutModalConfirm() {
    this.restartOSRBooking.emit();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.store.dispatch(setCurrentApplication({ application: null }));
    this.loadservice.resetLoadingCounter();
  }

  onOfflineSuccess() {
    this.onPaymentSuccess();
  }

  onErrorPaypal(err) {
    if (err?.message === 'Detected popup close') {
      this.toast.warning('Payment is canceled');
    } else if (Array.isArray(err?.errors) && err?.errors[0]) {
      try {
        const reason = (err?.errors[0] as string)?.match('(?<=Reason:).*')[0];
        this.paymentErrorModalPaypal.subTitle = reason || 'Something is wrong';
      } catch (error) {
        this.paymentErrorModalPaypal.subTitle = 'Something is wrong';
      }
    } else {
      this.paymentErrorModalPaypal.subTitle = err.message || 'Something is wrong';
    }
    this.paymentErrorModalPaypal.open();
  }

  onApprovePaypal() {
    this.onPaymentSuccess();
  }

  onAcceptClicked() {
    this.paymentErrorModalPaypal.closeModal(true);
  }

  openConsentFormPopup(): void {
    this.consentFormModalRef = this.modalService.open(
      UnderageConsentFormModalComponent,
      { size: 'lg', keyboard: false, backdrop: 'static', animation: true }
    );
    this.consentFormModalRef.componentInstance.userFullName = this.userProfile || 'Test Taker';
    this.consentFormModalRef.closed
      .pipe(first())
      .subscribe((result: 'accepted' | 'my-tests') => {
        if (result === 'my-tests') {
          this.restartOSRBooking.emit();
        }
      });
  }
}
