<div class="container-fluid">
  <div class="">
    <div class="flexbox header-box mb-2">
      <div class="d-flex align-items-center">
        <h2 class="underlined-title">Finalise payment</h2>
      </div>
    </div>
    <app-test-details-card
      [ssrComponent]="ssrComponent"
      [testStartUtcDatetime]="getTestDate$() | async"
      [testTime]="getTimeString + ''"
      [duration]="(getDuration$() | async) + ''"
      (onBackButtonClick)="onBackButtonClick.emit()"
      [hideEditButton]="true"
    >
      <app-ssr-test-type testType [type]="testType"></app-ssr-test-type>
      <app-ssr-list-item-test-format
        testFormat
        [format]="testFormat"
        [withIcon]="false"
      >
      </app-ssr-list-item-test-format>
      <ng-container testLocationName>{{ testCentreName }}</ng-container>
      <ng-container address>{{ address }}</ng-container>
    </app-test-details-card>
  </div>
  <div class="mt-3 mb-n3">
    <app-countdown-timer
      timerText="left to secure your booking"
      [targetTime]="timerTargetTime"
      [isOSRPaymentTimer]="true"
      [currentTime]="timerCurrentTime"
      (timeDone)="whenTimerDone()"
    ></app-countdown-timer>
  </div>
  <div class="">
    <h2 class="" i18n>
      Please provide your payment details and we'll book your test dates and
      times
    </h2>
  </div>
  <div *ngIf="paymentMethods.length > 0">
    <app-payment [paymentMethods]="paymentMethods" [paymentType]="'OSR'">
      <ng-template paymentPanel="TIPS">
        <app-tips
          [application]="currentApp"
          [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId"
          [paymentType]="'OSR'"
        >
        </app-tips>
      </ng-template>
      <ng-template paymentPanel="STRIPE">
        <app-stripe
          [application]="currentApp"
          [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId"
          [paymentType]="'OSR'"
        >
        </app-stripe>
      </ng-template>
      <ng-template paymentPanel="CREDIT_CARD">
        <div class="row">
          <div class="ms-2">
            <app-payment-card
              isPaymentCard
              #paymentCardElem
              [applicationPaymentId]="applicationPaymentId"
              [applicationId]="applicationId"
              (onCardTypeChange)="onCardTypeChange($event)"
              (onValidityChange)="onValidityChange($event)"
              (onPaymentStatusChange)="onPaymentStatusChange($event)"
              (onCreatedBraintree)="onCreateBraintree()"
              (onCreateError)="onCreateError()"
            >
            </app-payment-card>
          </div>
          <div class="col-xl-3 col-lg-5" *ngIf="enable3DSPayment">
            <div class="align-button">
              <app-billing-address-form
                [form]="addressDetailsForm"
              ></app-billing-address-form>
            </div>
          </div>
        </div>
        <div class="" #btnSection>
          <button
            [disabled]="
              !isPayButtonEnable ||
              (enable3DSPayment && !this.addressDetailsForm?.valid)
            "
            class="btn btn-highlight align-button"
            [class]="
              paymentStatus !== 'Error' ? 'btn-payAction' : 'btn-errorAction'
            "
            type="submit"
            (click)="OnPayClick()"
            i18n
          >
            <span *ngIf="paymentStatus === ''">
              Pay {{ this.pay | currency : this.payCode : "symbol-narrow" }}
              {{ this.payCode }}
            </span>
            <span *ngIf="paymentStatus === 'Error'">
              Payment failed, please retry with a different card
            </span>
            <span *ngIf="paymentStatus === 'Process'">
              Processing payment
            </span>
          </button>
        </div>
        <div class="bottom-section">
          <div class="wrapper">
            <span class="sub-copy" i18n
              >By proceeding, you agree to the Terms and Conditions</span
            >
            <div class="braintree">
              <span class="material-icons icons-security"> security </span>
              <i class="fa fa-lock icons-lock"></i>
              <img
                src="./../../../../../assets/images/payment/Icon-payment-braintree.svg"
                class="braintree-img" alt="braintree icon"
              />
              <span class="braintree-text" i18n
                >Payment is secured via an SSL 256bit encrypted gateway</span
              >
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template paymentPanel="PAYPAL" *ngIf="clientId">
        <app-paypal
          isPaymentCard
          [currency]="payCode"
          [clientId]="clientId"
          [purchaseItems]="purchaseItem"
          [purchaseTotal]="pay"
          (onApproval)="onApprovePaypal()"
          (onError)="onErrorPaypal($event)"
          [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId"
          [profileId]="profileId"
        >
        </app-paypal>
      </ng-template>
      <ng-template paymentPanel="VERITRANS">
        <app-veritrans
        [application]="currentApp"
        [tcPaymentMethod]="tcPaymentMethod"
          [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId"
          [paymentType]="'OSR'">
        </app-veritrans>
      </ng-template>
      <ng-template paymentPanel="PAYSTACK">
        <app-paystack [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-paystack>
      </ng-template>
      <ng-template paymentPanel="OFFLINE">
        <app-offline-wizard
          isPaymentCard
          (onSuccessPayment)="onOfflineSuccess()"
          [userName]="getApplicationUserName$ | async"
          [receiptNumber]="receiptNumber$ | async"
          [applicationPaymentId]="applicationPaymentId$ | async"
          [showBackButton]="false"
        ></app-offline-wizard>
      </ng-template>
      <ng-template paymentPanel="BANKALFALAH">
        <app-bank-alfalah [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-bank-alfalah>
      </ng-template>
      <ng-template paymentPanel="BRAC">
        <app-brac [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'" [isBracCyberSource]="bracCyberSource">
        </app-brac>
      </ng-template>
      <ng-template paymentPanel="ESEWA">
        <app-e-sewa [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-e-sewa>
      </ng-template>
      <ng-template paymentPanel="PAYDOLLAR">
        <app-paydollar [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-paydollar>
      </ng-template>
      <ng-template paymentPanel="NEWEBPAY">
        <app-newebpay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-newebpay>
      </ng-template>
      <ng-template paymentPanel="INIPAY">
        <app-inipay [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-inipay>
      </ng-template>
      <ng-template paymentPanel="HSBCOMNI">
        <app-hsbc-omni [tcPaymentMethod]="tcPaymentMethod" [applicationPaymentId]="applicationPaymentId"
          [applicationId]="applicationId" [application]="currentApp" [paymentType]="'OSR'">
        </app-hsbc-omni>
      </ng-template>
    </app-payment>
  </div>
</div>
<app-confirm-modal
  #restartBookingModal
  title="Oops! You've run out of time"
  subTitle="Sorry, you will need to choose your test times again."
  imageUrl="../../../../assets/images/boy-read-book.png"
  acceptText="Restart booking"
  [showReject]="false"
  acceptClass="btn-blue"
  (onClose)="timeOutModalConfirm()"
  (onAcceptClicked)="timeOutModalConfirm()"
>
</app-confirm-modal>
<app-confirm-modal
  #paymentErrorModalPaypal
  title="Payment Error"
  [showReject]="true"
  [acceptText]="'Close'"
  [showReject]="false"
  (onAcceptClicked)="onAcceptClicked()"
  imageClass="fa fa-exclamation-triangle fa-5x text-warning"
></app-confirm-modal>
